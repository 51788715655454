<mat-card>
  <mat-card-header>
    <mat-card-title>Work Entry</mat-card-title>
    <mat-card-subtitle>{{
      start.toDate() | date : "dd/MM/yyyy"
    }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="workGroup">
      <div class="field-group">
        <mat-form-field>
          <mat-label>Project</mat-label>
          <mat-select formControlName="project_id">
            <mat-option
              *ngFor="let project of projects | async"
              [value]="project.id"
              >{{ project.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Work Assignment</mat-label>
          <mat-select formControlName="work_assignment_id">
            <mat-option
              *ngFor="let work of workAssignments | async"
              [value]="work.id"
              >{{ work.title }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="field-group">
        <mat-form-field>
          <mat-label>Start time</mat-label>
          <input
            matInput
            formControlName="start"
            placeholder="Start Time"
            type="time"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>End time</mat-label>
          <input
            matInput
            formControlName="end"
            placeholder="End Time"
            type="time"
          />
          <mat-hint>{{timeDiff()|duration}}</mat-hint>
        </mat-form-field>
      </div>
      <h3>Items consumed</h3>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="save()" [disabled]="workGroup.invalid">
      Save
    </button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-card-actions>
</mat-card>
