<div class="frame" *ngIf="image | async as img">
  <div class="camera-view" *ngIf="img == 'null'">
    <webcam
      [width]="200"
      [allowCameraSwitch]="true"
      (imageCapture)="picTaken($event)"
      [imageQuality]="0.5"
      [trigger]="snap"
    ></webcam>
    <button mat-icon-button (click)="takePicture()">
      <mat-icon aria-label="label">camera</mat-icon>
    </button>
  </div>
  <div class="picture-view" *ngIf="img != 'null'">
    <img [src]="img" width="200px" alt="" />
    <button mat-icon-button (click)="reset()">
      <mat-icon aria-label="label">delete</mat-icon>
    </button>
    <button mat-icon-button (click)="returnPicture()">
      <mat-icon aria-label="label">save</mat-icon>
    </button>
  </div>
</div>
