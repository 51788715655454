import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PictureService {
  private _gridPics = new BehaviorSubject<{ image_data: string }[] | null>(
    null
  );
  constructor(private http: HttpClient) {}

  get gridPics() {
    return this._gridPics.asObservable();
  }

  getPictures(type: string | undefined, id: number | undefined) {
    return this.http.get<{ image_data: string }[]>(
      `api/pictures/${type}/${id}`
    );
  }

  addPictures(
    type: string | undefined,
    id: number | undefined,
    image_data: string
  ) {
    return this.http.post(`api/pictures/${type}/${id}`, { image_data });
  }

  deletePicture(id: number | undefined) {
    return this.http.delete(`api/pictures/${id}`);
  }

  openStack(pile: { image_data: string }[]) {
    this._gridPics.next(pile);
  }
  closeStack() {
    this._gridPics.next(null);
  }
}
