import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '@services/security.service';
import { Observable, mergeMap } from 'rxjs';

@Component({
  selector: 'sc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
})
export class HomeComponent {
  action$: Observable<any>;
  constructor(router: Router, route: ActivatedRoute, svc: SecurityService) {
    this.action$ = svc.userInfo.pipe(
      mergeMap((user) => {
        if (user == null) {
          return svc.initAuth();
        } else if (['admin', 'owner'].includes(user.mode)) {
          svc.mode.next('admin');
          return router.navigate(['/', 'admin', 'projects']);
        } else {
          svc.mode.next('staff');
          return router.navigate(['/', 'makers', 'work-requests']);
        }
      })
    );
  }
}
