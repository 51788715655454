import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SecurityService } from './services/security.service';
import { CoreModule } from './core/core.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TakePhotoComponent } from './take-photo/take-photo.component';
import { CameraButtonComponent } from './take-photo/camera-button/camera-button.component';
import { WebcamModule } from 'ngx-webcam';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { QrPrintoutComponent } from './qr-printout/qr-printout.component';
import { AuthService, AuthModule } from '@auth0/auth0-angular';
import { WorkItemsComponent } from './work-items/work-items.component';
import { WorkItemEntryComponent } from './work-items/work-item-entry/work-item-entry.component';
import { DurationPipe } from './duration.pipe';
import { ScanQrComponent } from './scan-qr/scan-qr.component';
import { ScanButtonComponent } from './scan-qr/scan-button/scan-button.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QuickQuantityComponent } from './quick-quantity/quick-quantity.component';
import {
  PicturePileComponent,
  PicPileDirective,
} from './picture-pile/picture-pile.component';
import { PictureGridComponent } from './picture-grid/picture-grid.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    TakePhotoComponent,
    CameraButtonComponent,
    GenericTableComponent,
    QrPrintoutComponent,
    WorkItemsComponent,
    WorkItemEntryComponent,
    DurationPipe,
    ScanQrComponent,
    ScanButtonComponent,
    QuickQuantityComponent,
    PicturePileComponent,
    PicPileDirective,
    PictureGridComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    WebcamModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
    MatCardModule,
    MatDatepickerModule,
    MatMenuModule,
    ZXingScannerModule,
    RouterModule,
  ],
  providers: [AuthService],
  exports: [
    CameraButtonComponent,
    GenericTableComponent,
    QrPrintoutComponent,
    WorkItemsComponent,
    DurationPipe,
    ScanButtonComponent,
    PicturePileComponent,
    PicPileDirective,
    PictureGridComponent,
  ],
})
export class SharedModule {}
