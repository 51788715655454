import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { Unit } from '@models/material';
import { MaterialsService } from '@services/materials.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'sc-quick-quantity',
  templateUrl: './quick-quantity.component.html',
  styleUrls: ['./quick-quantity.component.less'],
})
export class QuickQuantityComponent {
  units$: Observable<Unit[]>;
  title: string;
  unit: number;
  subtitle: string;
  quantGroup: FormGroup;
  constructor(
    private svc: MaterialsService,
    fb: FormBuilder,
    private dlg: MatDialogRef<QuickQuantityComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      unit: number;
      subtitle: string;
      quantity?: number;
      lock_unit?: boolean;
    }
  ) {
    this.units$ = svc.getUnits();
    this.quantGroup = fb.group({
      unit: new FormControl(
        {
          value: data.unit,
          disabled: data.lock_unit ?? false,
        },
        [Validators.required]
      ),
      quantity: [data.quantity, Validators.required],
    });
    this.title = data.title;
    this.unit = data.unit;
    this.subtitle = data.subtitle;
  }

  cancel() {
    this.dlg.close();
  }
  ok() {
    this.dlg.close(this.quantGroup.value);
  }
}
