<mat-card>
  <mat-card-header>
    <mat-card-title>Work Item</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="wiGroup">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" placeholder="Title" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Project</mat-label>
        <mat-select placeholder="Project" formControlName="project_id">
          <mat-option
            *ngFor="let project of project$ | async"
            [value]="project.id"
            >{{ project.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Staff</mat-label>
        <mat-select placeholder="Staff" formControlName="staff_id">
          <mat-option [value]="null">Unassigned</mat-option>
          <mat-option *ngFor="let staff of staff$ | async" [value]="staff.id">{{
            staff.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select placeholder="Status" formControlName="status">
          <mat-option
            *ngFor="let item of ['new', 'active', 'complete']"
            [value]="item"
            >{{ item }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Due By</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          placeholder="Due by"
          formControlName="due_by"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Notes</mat-label>
        <textarea
          cdkTextareaAutosize
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="10"
          #autosize="cdkTextareaAutosize"
          matInput
          formControlName="notes"
          placeholder="Notes"
          type="text"
        ></textarea>
      </mat-form-field>
      <sc-picture-pile
        type="work_assignment"
        [id]="wiGroup.value.id"
      ></sc-picture-pile>
      <sc-camera-button></sc-camera-button>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="save()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-card-actions>
</mat-card>
