import { Router } from '@angular/router';
import { ProjectService } from '@services/project.service';
import {
  filter,
  map,
  Observable,
  tap,
  mergeMap,
  of,
  combineLatest,
} from 'rxjs';
import { ScanQrComponent } from './../scan-qr.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MaterialsService } from '@services/materials.service';
import { QuickQuantityComponent } from '@shared/quick-quantity/quick-quantity.component';
import { WorkEntryComponent } from 'src/app/staff/time-sheet/time-sheet-detail/work-entry/work-entry.component';
import { WorkItemEntryComponent } from '@shared/work-items/work-item-entry/work-item-entry.component';
import { Project } from '@models/project';
import { Material } from '@models/material';

@Component({
  selector: 'sc-scan-button',
  templateUrl: './scan-button.component.html',
  styleUrls: ['./scan-button.component.less'],
})
export class ScanButtonComponent {
  action$?: Observable<unknown>;
  constructor(
    private dlg: MatDialog,
    private matt: MaterialsService,
    private proj: ProjectService,
    private router: Router
  ) {}
  @ViewChild(MatMenuTrigger) trigger?: MatMenuTrigger;

  items: { label: string; action: () => void }[] = [];

  scan() {
    this.action$ = this.dlg
      .open(ScanQrComponent)
      .afterClosed()
      .pipe(
        mergeMap((v) => {
          const matches =
            /https:\/\/sara-tailoring.ngrok.io\/admin\/(?<type>[^\/]*)\/(?<id>\d*)/.exec(
              v
            );
          if (matches == null) {
            if (/\d*/.test(v)) {
              return combineLatest({
                type: of('material'),
                material: this.matt.getByCode(v),
              });
            }
            return of(null);
          }
          console.log(matches);

          switch (matches.groups?.['type']) {
            case 'materials':
              return combineLatest({
                type: of('material'),
                material: this.matt.getMaterial(Number(matches.groups?.['id'])),
              });
            // return this.matt.getMaterial(Number(matches.groups?.['id'])).pipe(
            //   tap((v) => {
            //     if (v != null) {
            //       this.items = [
            //         {
            //           label: `Show details for ${v.name}`,
            //           action: () => {
            //             this.router.navigate(['/admin', 'materials', v.id]);
            //           },
            //         },
            //         {
            //           label: `Add stock of ${v.name}`,
            //           action: () => {
            //             this.action$ = this.dlg
            //               .open(QuickQuantityComponent, {
            //                 data: {
            //                   title: `Add stock`,
            //                   subtitle: v.name,
            //                   unit: v.unit_id,
            //                 },
            //               })
            //               .afterClosed();
            //           },
            //         },
            //         {
            //           label: `Add ${v.name} to current Work Item`,
            //           action: () => {
            //             this.action$ = this.dlg
            //               .open(QuickQuantityComponent, {
            //                 data: {
            //                   title: `Use stock`,
            //                   subtitle: v.name,
            //                   unit: v.unit_id,
            //                 },
            //               })
            //               .afterClosed();
            //           },
            //         },
            //       ];
            //     }
            //   })
            // );
            case 'projects':
              return combineLatest({
                type: of('project'),
                project: this.proj.getProject(Number(matches.groups?.['id'])),
              });
            // return this.proj.getProject(Number(matches.groups?.['id'])).pipe(
            //   tap((v) => {
            //     if (v != null) {
            //       this.items = [
            //         {
            //           label: `Show details for ${v.name}`,
            //           action: () => {
            //             this.router.navigate(['/admin', 'projects', v.id]);
            //           },
            //         },
            //         {
            //           label: `Add Work to ${v.name}`,
            //           action: () => {
            //             this.action$ = this.dlg
            //               .open(WorkItemEntryComponent, {
            //                 data: { project_id: v.id },
            //               })
            //               .afterClosed();
            //           },
            //         },
            //         {
            //           label: `Start work on ${v.name}`,
            //           action: () => {
            //             this.action$ = this.dlg
            //               .open(WorkEntryComponent, {
            //                 data: { project_id: v.id },
            //               })
            //               .afterClosed();
            //           },
            //         },
            //       ];
            //     }
            //   })
            // );
            case 'suppliers':
            case 'clients':
            default:
              return of(null);
          }
        }),
        filter(
          (
            v
          ): v is
            | { type: 'project'; project: Project }
            | { type: 'material'; material: Material } => v != null
        ),
        tap((result) => {
          if (result?.type == 'material') {
            const v = result.material;
            this.items = [
              {
                label: `Show details for ${v.name}`,
                action: () => {
                  this.router.navigate(['/admin', 'materials', v.id]);
                },
              },
              {
                label: `Add stock of ${v.name}`,
                action: () => {
                  this.action$ = this.dlg
                    .open(QuickQuantityComponent, {
                      data: {
                        title: `Add stock`,
                        subtitle: v.name,
                        unit: v.unit_id,
                        lock_unit:true
                      },
                    })
                    .afterClosed()
                    .pipe(
                      filter(
                        (v): v is { unit: number; quantity: number } =>
                          v != null
                      ),
                      mergeMap((v) =>
                        this.matt.addStock({ ...v, id: result.material.id })
                      ),
                    );
                },
              },
              {
                label: `Add ${v.name} to current Work Item`,
                action: () => {
                  this.action$ = this.dlg
                    .open(QuickQuantityComponent, {
                      data: {
                        title: `Use stock`,
                        subtitle: v.name,
                        unit: v.unit_id,
                      },
                    })
                    .afterClosed();
                },
              },
            ];
          } else if (result?.type == 'project') {
            const v = result.project;
            this.items = [
              {
                label: `Show details for ${v.name}`,
                action: () => {
                  this.router.navigate(['/admin', 'projects', v.id]);
                },
              },
              {
                label: `Add Work to ${v.name}`,
                action: () => {
                  this.action$ = this.dlg
                    .open(WorkItemEntryComponent, {
                      data: { project_id: v.id },
                    })
                    .afterClosed();
                },
              },
              {
                label: `Start work on ${v.name}`,
                action: () => {
                  this.action$ = this.dlg
                    .open(WorkEntryComponent, {
                      data: { project_id: v.id },
                    })
                    .afterClosed();
                },
              },
            ];
          }
        }),
        tap((v) => console.log(v)),
        tap((v) => this.trigger?.openMenu())
      );
  }
}
