import { Observable } from 'rxjs';
import { TakePhotoComponent } from './../take-photo.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PicturePileComponent } from '@shared/picture-pile/picture-pile.component';

@Component({
  selector: 'sc-camera-button',
  templateUrl: './camera-button.component.html',
  styleUrls: ['./camera-button.component.less'],
})
export class CameraButtonComponent {
  action$?: Observable<unknown>;
  @Output()
  picture = new EventEmitter<string>();

  @Input()
  pile?: PicturePileComponent;

  constructor(private dlg: MatDialog) {}
  takePhoto() {
    this.action$ = this.dlg
      .open<TakePhotoComponent, null, string>(TakePhotoComponent)
      .afterClosed()
      .pipe(
        tap((v) => {
          if (v != null) {
            if (this.pile != null) {
              this.pile.addPhoto(v);
            }
            this.picture.next(v);
          }
        })
      );
  }
}
