<button mat-fab (click)="scan()">
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let item of items" (click)="item.action()">
      {{ item.label }}
    </button>
  </mat-menu>
  <mat-icon aria-label="label">qr_code</mat-icon>
</button>
<button #trigger mat-button [matMenuTriggerFor]="menu" class="microdot"></button>

<ng-container *ngIf="action$ | async"></ng-container>
