import { MaterialType } from './../models/material_type';
import { TableService } from '@shared/generic-table/generic-table.component';
import { Unit } from './../models/material';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Material } from '@models/material';
import { combineLatest, Observable, Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { mergeMap, startWith, tap } from 'rxjs/operators';
import { PagedData } from '@shared/base-classes/generic-data-source';
import { stockOrderData } from 'src/app/admin/materials/stock-order/stock-order.component';

export type stock_status =
  | 'accepted'
  | 'cancelled'
  | 'new'
  | 'ordered'
  | 'received'
  | 'returned';

@Injectable({
  providedIn: 'root',
})
export class MaterialsService implements TableService<Material> {
  refresh = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  getData(
    sort: MatSort,
    paginator: MatPaginator
  ): Observable<PagedData<Material>> {
    return combineLatest({
      sort: sort.sortChange.pipe(
        startWith({ active: '', direction: 'asc' } as Sort)
      ),
      range: paginator.page.pipe(startWith({ pageSize: 10, pageIndex: 0 })),
    }).pipe(
      mergeMap(({ sort, range }) => {
        return this.http.get<PagedData<Material>>('api/materials/paged', {
          params: {
            sortBy: sort.active,
            sortDir: sort.direction,
            pageSize: range?.pageSize,
            startPage: range.pageIndex,
          },
        });
      })
    );
  }

  getMaterials(params?: any): Observable<Material[]> {
    return this.http.get<Material[]>('api/materials', { params });
  }

  getMaterialsQR() {
    return this.http.get<Material[]>('api/materials/qr');
  }

  getMaterial(id: any) {
    return this.http.get<Material>(`api/materials/${id}`);
  }

  getUnits() {
    return this.http.get<Unit[]>('api/units');
  }

  saveMaterial(data: Material) {
    const { id, ...material } = data;
    if (id == null) {
      return this.http.post<Material>('api/materials', material);
    } else {
      return this.http.put<Material>(`api/materials/${id}`, material);
    }
  }

  getMaterialType(id: number) {
    return this.http.get<MaterialType>(`api/material_types/${id}`);
  }

  addCode(id: number | undefined, code: string): any {
    return this.http.patch(`api/materials/${id}/code`, { code });
  }

  getByCode(code: string) {
    return this.http.get<Material>(`api/materials/code`, { params: { code } });
  }

  registerOrder(v: stockOrderData) {
    return this.http
      .post('api/stock-orders', v)
      .pipe(tap(() => this.refresh.next(true)));
  }

  getOrders(params: {
    supplier_id?: number;
    status?: stock_status;
    material_id?: number;
  }): Observable<stockOrderData[]> {
    return this.http.get<stockOrderData[]>('api/stock-orders', { params });
  }

  receiveOrder(order: stockOrderData & { received: boolean }) {
    return this.http
      .put(`api/stock-orders/${order.id}`, {})
      .pipe(tap(() => this.refresh.next(true)));
  }

  updateStock(data: { id: number; unit: number; quantity: number }): any {
    const { id, ...theData } = data;
    return this.http
      .patch(`api/materials/${id}`, theData)
      .pipe(tap(() => this.refresh.next(true)));
  }

  addStock(data: { id: number; unit: number; quantity: number }): any {
    const { id, ...theData } = data;
    return this.http
      .patch(`api/materials/${id}/add`, theData)
      .pipe(tap(() => this.refresh.next(true)));
  }
}
