import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthService,
  AuthClientConfig,
  AuthConfig,
  User,
} from '@auth0/auth0-angular';
import { PagedData } from '@shared/base-classes/generic-data-source';
import {
  tap,
  mergeMap,
  firstValueFrom,
  of,
  Observable,
  BehaviorSubject,
} from 'rxjs';

export type AppUser = User & {
  mode: string;
  staff_id: number | null;
};

const authConfig: Partial<AuthConfig> = {
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  httpInterceptor: { allowedList: ['https://localhost'] },
};

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  userInfo: Observable<AppUser | null>;
  mode: BehaviorSubject<'staff' | 'admin'> = new BehaviorSubject<'staff' | 'admin'>('staff');
  constructor(
    private auth: AuthService,
    private cc: AuthClientConfig,
    private http: HttpClient
  ) {
    this.userInfo = auth.isAuthenticated$.pipe(
      mergeMap((v) => auth.user$),
      tap((v) => console.log(v)),
      mergeMap((v) => this.loggedIn(v))
    );
  }

  loggedIn(user: User | null | undefined) {
    if (typeof user == 'object') {
      return this.http.post<AppUser>('api/security/login', user);
    }
    return of(null);
  }

  async initAuth() {
    return firstValueFrom(
      this.http.get<{ clientId: string; domain: string }>('api/security').pipe(
        tap((settings) => {
          const trueConfig: AuthConfig = { ...authConfig, ...settings };
          this.cc.set(trueConfig);
        })
      )
    );
  }

  getLogins(): Observable<PagedData<AppUser> | AppUser[]> {
    return this.http.get<AppUser[]>('api/security/logins');
  }

  updateLogin({
    mode,
    staff_id,
    sub,
  }: User & { mode: string; staff_id: number | null }): any {
    return this.http.patch(`api/security/${sub}`, { mode, staff_id });
  }

  deleteLogin({ sub }: User) {
    return this.http.delete(`api/security/${sub}`);
  }
}
