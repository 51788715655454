import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor() {}

  compare<T extends object>(
    specialised?: Record<
      keyof T | string,
      (
        a: T,
        b: T
      ) => undefined | number | (string | number | Moment | unknown)[]
    >
  ): (key: keyof T, a: T, b: T) => number {
    return (key: keyof T, a: T, b: T) => {
      let aVal: any = a[key];
      let bVal: any = b[key];
      if (specialised != null) {
        const result = key in specialised ? specialised[key](a, b) : null;
        if (typeof result == 'number') return result;
        if (result != null && Array.isArray(result) && result.length == 2) {
          aVal = result[0];
          bVal = result[1];
        }
      }
      const keyCast = key as keyof T;
      if (typeof aVal == typeof bVal && aVal != null && bVal != null)
        return aVal < bVal ? -1 : 1;
      if (aVal == null && bVal != null) return 1;
      if (aVal != null && bVal == null) return -1;
      return 0;
    };
  }

  duration(dur: moment.Duration | string | undefined) {
    if (dur == null) return null;
    const trueDuration: moment.Duration = moment.isDuration(dur)
      ? dur
      : moment.duration(dur);
    return `${Math.floor(trueDuration.asHours())}h${trueDuration
      .get('minutes')
      .toString()
      .padStart(2, '0')}m`;
  }
}
