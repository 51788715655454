import { Component } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { AppUser, SecurityService } from '@services/security.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'sc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  user$: Observable<User | undefined | null>;
  appUser$: Observable<AppUser | null>;
  mode$: Observable<'staff' | 'admin'>;

  public set mode(value: 'staff' | 'admin') {
    this.sec.mode.next(value);
  }

  title = "Sara's Tailoring App";
  list: { text: string; link: string; icon: string }[] = [];

  lists = {
    staff: [
      { text: 'Timesheet', link: 'makers/time-sheet', icon: 'schedule' },
      { text: 'Work Requests', link: 'makers/work-requests', icon: 'work' },
      { text: 'Reports', link: 'reports', icon: 'summarize' },
    ],
    admin: [
      { text: 'Customers', link: 'admin/customers', icon: 'movie' },
      { text: 'Projects', link: 'admin/projects', icon: 'conveyor_belt' },
      { text: 'Suppliers', link: 'admin/suppliers', icon: 'forklift' },
      { text: 'Work Items', link: 'admin/work-items', icon: 'receipt_long' },
      { text: 'Materials', link: 'admin/materials', icon: 'checkroom' },
      { text: 'Makers', link: 'admin/makers', icon: 'people' },
      { text: 'Logins', link: 'admin/logins', icon: 'lock_open' },
      { text: 'Reports', link: 'reports', icon: 'summarize' },
    ],
  };

  constructor(private auth: AuthService, private sec: SecurityService) {
    this.mode$ = sec.mode.pipe(tap((mode) => (this.list = this.lists[mode])));
    this.user$ = auth.user$.pipe(tap((v) => console.log(v)));
    this.appUser$ = auth.user$.pipe(mergeMap((user) => sec.loggedIn(user)));
  }

  logout() {
    this.auth.logout({
      logoutParams: { returnTo: 'http://localhost/admin/staff' },
    });
  }
}
