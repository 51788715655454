import { Observable } from 'rxjs';
import { PictureService } from '@services/picture.service';
import { Component } from '@angular/core';

@Component({
  selector: 'sc-picture-grid',
  templateUrl: './picture-grid.component.html',
  styleUrls: ['./picture-grid.component.less'],
})
export class PictureGridComponent {
  pics$: Observable<{ image_data: string }[] | null>;
  constructor(private svc: PictureService) {
    this.pics$ = svc.gridPics;
  }

  close() {
    this.svc.closeStack();
  }
}
