import { StaffService } from '@services/staff.service';
import { Observable } from 'rxjs';
import { ProjectService } from '@services/project.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WorkItem } from '@models/work-item';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AfterViewChecked, Component, Inject, ViewChild } from '@angular/core';
import { Project } from '@models/project';
import { Staff } from '@models/staff';
import { PicturePileComponent } from '@shared/picture-pile/picture-pile.component';
import { CameraButtonComponent } from '@shared/take-photo/camera-button/camera-button.component';

@Component({
  selector: 'sc-work-item-entry',
  templateUrl: './work-item-entry.component.html',
  styleUrls: ['./work-item-entry.component.less'],
})
export class WorkItemEntryComponent implements AfterViewChecked {
  @ViewChild(CameraButtonComponent) cam?: any;
  @ViewChild(PicturePileComponent) pile?: any;
  wiGroup: FormGroup;
  project$: Observable<Project[]>;
  staff$: Observable<Staff[]>;
  constructor(
    private dlg: MatDialogRef<WorkItemEntryComponent, WorkItem>,
    projects: ProjectService,
    staff: StaffService,
    @Inject(MAT_DIALOG_DATA) data: WorkItem,
    fb: FormBuilder
  ) {
    this.project$ = projects.getProjects();
    this.staff$ = staff.getStaff();
    this.wiGroup = fb.group({
      id: [data.id],
      project_id: [data.project_id],
      staff_id: [data.staff_id],
      title: [data.title],
      notes: [data.notes],
      status: [data.status ?? 'new'],
      due_by: [data.due_by],
    });
  }

  cancel() {
    this.dlg.close();
  }

  save() {
    this.dlg.close(this.wiGroup.value);
  }

  ngAfterViewChecked(): void {
    if (this.cam != null && this.pile != null) {
      this.cam.pile = this.pile;
    }
  }
}
