<mat-card>
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="quantGroup">
      <mat-form-field>
        <mat-label>Quantity</mat-label>
        <input
          matInput
          formControlName="quantity"
          placeholder="Quantity"
          type="number"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Units</mat-label>
        <mat-select placeholder="Units" formControlName="unit">
          <mat-option *ngFor="let item of units$ | async" [value]="item.id">{{
            item.name
          }}</mat-option>
        </mat-select></mat-form-field
      >
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="ok()" [disabled]="quantGroup.invalid">
      OK
    </button>
    <button mat-button (click)="cancel()">Cancel</button>
  </mat-card-actions>
</mat-card>
