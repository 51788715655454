<mat-toolbar
  color="primary"
  class="mat-elevation-z6"
  *ngIf="mode$ | async as currMode"
>
  <button mat-icon-button (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="fill">{{ title }}</span>
  <ng-container
    *ngIf="['admin', 'owner'].includes((appUser$ | async)?.mode ?? 'none')"
  >
    <button
      mat-icon-button
      (click)="mode = 'staff'"
      *ngIf="currMode == 'admin'"
      title="Enter staff mode"
    >
      <mat-icon>admin_panel_settings</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="mode = 'admin'"
      *ngIf="currMode == 'staff'"
      title="Enter admin mode"
    >
      <mat-icon>people</mat-icon>
    </button>
  </ng-container>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #sidenav
    class="mat-elevation-z4"
    mode="over"
    [opened]="false"
    width="90px"
  >
    <mat-nav-list>
      <mat-list-item
        *ngFor="let item of list"
        [routerLink]="item.link"
        (click)="sidenav.close()"
        class="sc-menu-list-item"
      >
        <mat-icon matListIcon class="menu-icon">{{ item.icon }}</mat-icon>
        <span matLine class="menu-text">{{ item.text }}</span>
      </mat-list-item>
    </mat-nav-list>
    <div class="login-info" *ngIf="user$ | async as user">
      <div class="av">
        <img [src]="user.picture" alt="Avatar" class="avatar" />
      </div>
      <div class="name">
        <div class="name">{{ user.name }}</div>
        <div class="logout">
          <button
            mat-mini-fab
            color="primary"
            title="Logout"
            (click)="logout()"
          >
            <mat-icon aria-label="label">logout</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <div class="body-container">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
<sc-scan-button></sc-scan-button>
<sc-picture-grid></sc-picture-grid>
<ng-container *ngIf="appUser$ | async"></ng-container>
