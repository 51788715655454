import { UtilityService } from '@services/utility.service';
import { map, Observable } from 'rxjs';
import { ProjectService } from '@services/project.service';
import { TimeEntry } from '@models/time-entry';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { TimesheetsService } from '@services/timesheets.service';
import { WorkItem } from '@models/work-item';

@Component({
  selector: 'sc-work-entry',
  templateUrl: './work-entry.component.html',
  styleUrls: ['./work-entry.component.less'],
})
export class WorkEntryComponent {
  workGroup: FormGroup;
  start: moment.Moment;
  end: moment.Moment | null;
  projects: any;
  workAssignments: Observable<WorkItem[]>;
  constructor(
    fb: FormBuilder,
    svc: ProjectService,
    private dlg: MatDialogRef<WorkEntryComponent>,
    private utils: UtilityService,
    @Inject(MAT_DIALOG_DATA) data?: TimeEntry
  ) {
    this.workAssignments = svc
      .getAllWorkItems({})
      .pipe(map((v) => ('data' in v ? v.data : v)));
    this.projects = svc.getProjects();
    this.start = data?.start == null ? moment() : moment(data?.start);
    this.end = data?.start == null ? null : moment(data?.end);
    this.workGroup = fb.group({
      id: [data?.id],
      project_id: [data?.project_id, Validators.required],
      work_assignment_id: [data?.work_assignment?.id, Validators.required],
      start: [this.start.format('HH:mm'), Validators.required],
      end: [this.end?.format('HH:mm')],
    });
  }

  save() {
    this.dlg.close(this.workGroup.value);
  }

  cancel() {
    this.dlg.close();
  }

  timeDiff() {
    const { start, end } = this.workGroup.value;
    const startMo = moment(start, 'HH:mm');
    const endMo = moment(end, 'HH:mm');
    const moDur = moment.duration(endMo.diff(startMo));
    return moDur.toISOString();
  }
}
