<div class="scan">
  <span>Scanner</span>
  <zxing-scanner
    [formats]="allowedFormats"
    [autostart]="true"
    (scanSuccess)="eventer($event)"
    previewFitMode="scale-down"
    [autofocusEnabled]="true"
    [tryHarder]="true"
    (camerasFound)="camsFound($event)"
    [(device)]="device"
    (torchCompatible)="torchOpt($event)"
    #scanner
  ></zxing-scanner>
  <div *ngIf="cams">
    <mat-select
      placeholder="Camera"
      [value]="scanner.device"
      (valueChange)="setDevice($event)"
    >
      <mat-option [value]="cam" *ngFor="let cam of cams">{{
        cam.label
      }}</mat-option>
    </mat-select>
  </div>
</div>
