import { BehaviorSubject, filter } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { MatSelectChange } from '@angular/material/select';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@Component({
  selector: 'sc-scan-qr',
  templateUrl: './scan-qr.component.html',
  styleUrls: ['./scan-qr.component.less'],
})
export class ScanQrComponent {
  @ViewChild('scanner') scanner?: ZXingScannerComponent;
  device?: MediaDeviceInfo;

  cams?: MediaDeviceInfo[];
  torcher?: boolean;
  constructor(private dlg: MatDialogRef<ScanQrComponent>) {
    const scanCam = localStorage.getItem('scancam');
    if (scanCam != null) {
      const theCam = JSON.parse(scanCam);
      if (typeof theCam == 'object' && 'deviceId' in theCam)
        this.device = theCam;
    }
  }
  eventer($event: string) {
    this.dlg.close($event);
  }

  allowedFormats = [
    BarcodeFormat.QR_CODE,
    BarcodeFormat.EAN_13,
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX /*, ...*/,
  ];

  camsFound($event: MediaDeviceInfo[]) {
    this.cams = $event;
  }

  torchOpt($event: boolean) {
    console.log('torch?', $event);
    this.torcher = $event;
  }

  setDevice($event: MediaDeviceInfo) {
    console.log($event);
    console.log(this.scanner);
    this.device = $event;
    localStorage.setItem('scancam', JSON.stringify($event));
  }
}
