import {
  Observable,
  of,
  mergeMap,
  combineLatest,
  map,
  tap,
  range,
  delay,
  timer,
  filter,
} from 'rxjs';
import {
  Component,
  Directive,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { PictureService } from '@services/picture.service';

@Directive({ exportAs: 'pile', selector: '[pile]' })
export class PicPileDirective {}

@Component({
  selector: 'sc-picture-pile',
  exportAs: 'picpile',
  templateUrl: './picture-pile.component.html',
  styleUrls: ['./picture-pile.component.less'],
})
export class PicturePileComponent implements OnInit {
  @Input()
  type?: 'project' | 'work_assignment' | 'material';

  @Input()
  id?: number;

  pile?: Observable<{ image_data: string }[]>;
  active?: number;
  mode: 'pile' | 'grid' = 'pile';

  clicker(thePile: { image_data: string }[]) {
    this.svc.openStack(thePile);
  }

  constructor(private svc: PictureService) {}

  ngOnInit(): void {
    this.pile = this.pileLoop();
  }

  private pileLoop(): Observable<{ image_data: string }[]> {
    return this.svc.getPictures(this.type, this.id).pipe(
      mergeMap((pics) =>
        combineLatest({
          pics: of(pics),
          looper: timer(0, 5000).pipe(map((v) => v % pics.length)),
        })
      ),
      tap(({ looper }) => {
        this.active = looper;
      }),
      map(({ pics }) => pics)
    );
  }

  addPhoto(data: string) {
    this.pile = this.svc
      .addPictures(this.type, this.id, data)
      .pipe(mergeMap((v) => this.pileLoop()));
  }
}
