<div
  class="pile"
  *ngIf="pile | async as thePile"
  [ngClass]="{ grid: mode == 'grid', pile: mode == 'pile' }"
  (click)="clicker(thePile)"
>
  <div
    class="picdata"
    *ngFor="let item of thePile; index as ind"
    class="pic"
    [ngClass]="{ visible: ind == active }"
  >
    <img [src]="item.image_data" alt="Image {{ ind }}/{{ thePile.length }}" />
    <div class="label">Image {{ ind + 1 }} of {{ thePile.length }}</div>
  </div>
</div>
