import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Staff } from '@models/staff';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  constructor(private http: HttpClient) {}

  getStaffMember(id: number) {
    return this.http.get<Staff>(`api/staff/${id}`);
  }

  getStaff(): Observable<Staff[]> {
    return this.http.get<Staff[]>('api/staff');
  }
}
