import { BehaviorSubject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { WebcamImage } from 'ngx-webcam';

@Component({
  selector: 'sc-take-photo',
  templateUrl: './take-photo.component.html',
  styleUrls: ['./take-photo.component.less'],
})
export class TakePhotoComponent {
  private _snap = new BehaviorSubject(false);
  image = new BehaviorSubject<string>('null');
  get snap() {
    return this._snap.pipe(
      filter((v) => v),
      map((v) => {
        return;
      })
    );
  }
  constructor(private dlg: MatDialogRef<TakePhotoComponent>) {}

  takePicture() {
    this._snap.next(true);
  }

  returnPicture() {
    this.dlg.close(this.image.value);
  }

  picTaken(evt: WebcamImage) {
    this.image.next(evt.imageAsDataUrl);
  }

  reset() {
    this.dlg.close(null);
  }
}
