<div
  class="pile"
  *ngIf="pics$ | async as thePile"
  [ngClass]="{ visible: thePile != null && thePile.length > 0 }"
  (click)="close()"
>
  <div class="picdata" *ngFor="let item of thePile; index as ind" class="pic">
    <img [src]="item.image_data" alt="Image {{ ind }}/{{ thePile.length }}" />
    <div class="label">Image {{ ind + 1 }} of {{ thePile.length }}</div>
  </div>
</div>
