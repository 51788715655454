import { MomentInput } from 'moment';
import { mergeMap, filter, startWith, repeat } from 'rxjs/operators';
import { Project } from '@models/project';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, Subject, connectable, of } from 'rxjs';
import { PagedData } from '@shared/base-classes/generic-data-source';
import { WorkItem } from '@models/work-item';
import { TimeEntry } from '@models/time-entry';

export type workStatusFilter = 'active' | 'complete' | 'new' | 'all';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private _updated = new Subject<number>();

  constructor(private http: HttpClient) {}

  getProjects(params?: any): Observable<Project[]> {
    return this.http.get<Project[]>(`api/projects`, { params }).pipe();
  }

  getProjectsQR(params?: any): Observable<Project[]> {
    return this.http.get<Project[]>(`api/projects/qr`, { params }).pipe();
  }

  getProject(id: any) {
    return this.http.get<Project>(`api/projects/${id}`);
  }

  saveProject(project: Project) {
    const { id, ...data } = project;
    if (id == null) {
      return this.http.post(`api/projects`, data);
    } else {
      return this.http.put(`api/projects/${id}`, data);
    }
  }

  updater(p: number) {
    return connectable(this._updated.pipe(startWith(p))).pipe(
      filter((px) => {
        return px == p;
      })
    );
  }

  getTimeEntries(
    proj: Observable<number>
  ): Observable<PagedData<TimeEntry> | TimeEntry[]> {
    return proj.pipe(
      mergeMap((p) => this.http.get<TimeEntry[]>(`api/projects/${p}/time`))
    );
  }

  getAllWorkItems(params: { cust?: number; project?: number; maker?: number }) {
    return this.http.get<PagedData<WorkItem> | WorkItem[]>(
      `api/work-assignments`,
      {
        params,
      }
    );
  }

  getWorkItems(
    proj: Observable<number>,
    filt: Observable<workStatusFilter>
  ): Observable<PagedData<WorkItem>> {
    return combineLatest({
      project: proj, //.pipe(mergeMap((p) => this.updater(p))),
      filt,
    }).pipe(
      mergeMap(({ project, filt }) => {
        return of(0).pipe(
          repeat({
            delay: () => this._updated.pipe(filter((px) => px == project)),
          }),
          mergeMap(() =>
            this.http.get<PagedData<WorkItem>>(`api/projects/${project}/work`, {
              params: { filter: filt },
            })
          )
        );
      })
    );
  }

  update(p: number) {
    this._updated.next(p);
  }

  saveworkItem(wi: WorkItem): any {
    if (wi.id != null) {
      return this.http.put(`api/work-assignments/${wi.id}`, wi);
    }
    return this.http.post('api/work-assignments', wi);
  }

  checkActive() {
    return this.http.get<TimeEntry | null>('api/work-assignments/active');
  }

  endWorkItem(v: TimeEntry): any {
    return this.http.post(`api/timesheets/time-entry/${v.id}`, v);
  }
}
