import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: moment.DurationInputArg1, ...args: unknown[]): unknown {
    const dur = moment.duration(value);
    return `${Math.floor(dur.asHours())}h${dur.minutes()}m`;
  }
}
