import { MatSnackBarModule } from '@angular/material/snack-bar';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { LayoutModule } from '@angular/cdk/layout';
import { AuthClientConfig, AuthModule } from '@auth0/auth0-angular';
import { SecurityService } from '@services/security.service';
import { ServerAuthInterceptor } from './server-auth.interceptor';
import { SharedModule } from '@shared/shared.module';
import { HomeComponent } from './home/home.component';

const clientConfig = {
  domain: 'dev-bou65cur.us.auth0.com',
  clientId: 'xPBO79Y2IIxUY7X4a8oyz2pDnpyp9bM7',
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
  httpInterceptor: { allowedList: ['https://localhost', 'http://localhost'] },
};
@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    HttpClientModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSnackBarModule,
    LayoutModule,
    AuthModule.forRoot(clientConfig),
    SharedModule
  ],
  providers: [
    AuthClientConfig,
    SecurityService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerAuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (secService: SecurityService) => () => secService.initAuth(),
      deps: [SecurityService, AuthClientConfig],
      multi: true,
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'DD/MM/YYYY',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
